<template>
    <div>
        <div class="hb-primary hb-design-system-border-radius px-4 white--text mb-6">
            <hb-header :divider="false" one-action>
                <template v-slot:left>
                    <hb-page-header title="Notifications">
                        <template v-slot:description>
                            <div>import { HbNotification } from 'hummingbird-aviary';</div>
                        </template>
                    </hb-page-header>
                </template>
                <template v-slot:right>
                    <hb-btn icon tooltip="Reset This Page" @click="resetPage" active-state-off>mdi-power</hb-btn>
                </template>
            </hb-header>
        </div>

        <hb-card title="Caution Notification + Code" class="mt-4 mb-6">
            <v-row class="pa-0 ma-0 mx-6 mt-6">
                <hb-notification
                    v-model="notification1"
                    v-if="notification1"
                    type="caution"
                >
                    Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                    <template v-slot:condensedActions>
                        <hb-menu
                            options
                            options-hover-type="caution"
                        >
                            <v-list>
                                <v-list-item :ripple="false" @click="notification1 = false">
                                    <v-list-item-title>Action</v-list-item-title>
                                </v-list-item>
                            </v-list>
                        </hb-menu>
                    </template>
                    <template v-slot:actions>
                        <hb-btn small color="secondary" @click="notification1 = false">Action</hb-btn>
                    </template>
                </hb-notification>
            </v-row>
            <v-row class="pa-0 ma-0 mx-6 mt-3">
                <hb-notification
                    v-model="notification2"
                    v-if="notification2"
                    type="caution"
                >
                    Lorem ipsum dolor sit amet, consectetur adipiscing elit. Donec quis non amet. Lorem ipsum dolor sit amet, consectetur adipiscing elit. Donec quis non amet...
                    <template v-slot:condensedActions>
                        <hb-menu
                            options
                            options-hover-type="caution"
                        >
                            <v-list>
                                <v-list-item :ripple="false" @click="notification2 = false">
                                    <v-list-item-title>Action</v-list-item-title>
                                </v-list-item>
                            </v-list>
                        </hb-menu>
                    </template>
                    <template v-slot:actions>
                        <hb-btn small color="secondary" @click="notification2 = false">Action</hb-btn>
                    </template>
                </hb-notification>
            </v-row>

            <v-row class="my-6 d-flex justify-center">
                <hb-btn color="primary" @click="grabCode('caution')">Grab Code</hb-btn>
                <div id="caution" style="position: absolute;z-index:-999999;top:0;left:0;">
                    &lt;hb-notification
                        v-model="notification1"
                        v-if="notification1"
                        type="caution"
                    >
                        Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                        &lt;template v-slot:condensedActions>
                            &lt;hb-menu
                                options
                                options-hover-type="caution"
                            >
                                &lt;v-list>
                                    &lt;v-list-item :ripple="false" @click="notification1 = false">
                                        &lt;v-list-item-title>Action&lt;/v-list-item-title>
                                    &lt;/v-list-item>
                                &lt;/v-list>
                            &lt;/hb-menu>
                        &lt;/template>
                        &lt;template v-slot:actions>
                            &lt;hb-btn small color="secondary" @click="notification1 = false">Action&lt;/hb-btn>
                        &lt;/template>
                    &lt;/hb-notification>
                </div>
            </v-row>

            <hb-card class="pa-6" color="#E0F5F5" no-title>
<pre>
&lt;hb-notification
    v-model="notification1"
    v-if="notification1"
    type="caution"
>
    Lorem ipsum dolor sit amet, consectetur adipiscing elit.
    &lt;template v-slot:condensedActions>
        &lt;hb-menu
            options
            options-hover-type="caution"
        >
            &lt;v-list>
                &lt;v-list-item :ripple="false" @click="notification1 = false">
                    &lt;v-list-item-title>Action&lt;/v-list-item-title>
                &lt;/v-list-item>
            &lt;/v-list>
        &lt;/hb-menu>
    &lt;/template>
    &lt;template v-slot:actions>
        &lt;hb-btn small color="secondary" @click="notification1 = false">Action&lt;/hb-btn>
    &lt;/template>
&lt;/hb-notification>
</pre>
            </hb-card>
        </hb-card>

        <hb-card title="Error Notification + Code" class="mt-4 mb-6">
            <v-row class="pa-0 ma-0 mx-6 mt-6">
                <hb-notification
                    v-model="notification3"
                    v-if="notification3"
                    type="error"
                >
                    Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                    <template v-slot:condensedActions>
                        <hb-menu
                            options
                            options-hover-type="error"
                        >
                            <v-list>
                                <v-list-item :ripple="false" @click="notification3 = false">
                                    <v-list-item-title>Action</v-list-item-title>
                                </v-list-item>
                            </v-list>
                        </hb-menu>
                    </template>
                    <template v-slot:actions>
                        <hb-btn small color="secondary" @click="notification3 = false">Action</hb-btn>
                    </template>
                </hb-notification>
            </v-row>
            <v-row class="pa-0 ma-0 mx-6 mt-3">
                <hb-notification
                    v-model="notification4"
                    v-if="notification4"
                    type="error"
                >
                    Lorem ipsum dolor sit amet, consectetur adipiscing elit. Donec quis non amet. Lorem ipsum dolor sit amet, consectetur adipiscing elit. Donec quis non amet...
                    <template v-slot:condensedActions>
                        <hb-menu
                            options
                            options-hover-type="error"
                        >
                            <v-list>
                                <v-list-item :ripple="false" @click="notification4 = false">
                                    <v-list-item-title>Action</v-list-item-title>
                                </v-list-item>
                            </v-list>
                        </hb-menu>
                    </template>
                    <template v-slot:actions>
                        <hb-btn small color="secondary" @click="notification4 = false">Action</hb-btn>
                    </template>
                </hb-notification>
            </v-row>

            <v-row class="my-6 d-flex justify-center">
                <hb-btn color="primary" @click="grabCode('error')">Grab Code</hb-btn>
                <div id="error" style="position: absolute;z-index:-999999;top:0;left:0;">
                    &lt;hb-notification
                        v-model="notification3"
                        v-if="notification3"
                        type="error"
                    >
                        Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                        &lt;template v-slot:condensedActions>
                            &lt;hb-menu
                                options
                                options-hover-type="error"
                            >
                                &lt;v-list>
                                    &lt;v-list-item :ripple="false" @click="notification3 = false">
                                        &lt;v-list-item-title>Action&lt;/v-list-item-title>
                                    &lt;/v-list-item>
                                &lt;/v-list>
                            &lt;/hb-menu>
                        &lt;/template>
                        &lt;template v-slot:actions>
                            &lt;hb-btn small color="secondary" @click="notification3 = false">Action&lt;/hb-btn>
                        &lt;/template>
                    &lt;/hb-notification>
                </div>
            </v-row>

            <hb-card class="pa-6" color="#E0F5F5" no-title>
<pre>
&lt;hb-notification
    v-model="notification3"
    v-if="notification3"
    type="error"
>
    Lorem ipsum dolor sit amet, consectetur adipiscing elit.
    &lt;template v-slot:condensedActions>
        &lt;hb-menu
            options
            options-hover-type="error"
        >
            &lt;v-list>
                &lt;v-list-item :ripple="false" @click="notification3 = false">
                    &lt;v-list-item-title>Action&lt;/v-list-item-title>
                &lt;/v-list-item>
            &lt;/v-list>
        &lt;/hb-menu>
    &lt;/template>
    &lt;template v-slot:actions>
        &lt;hb-btn small color="secondary" @click="notification3 = false">Action&lt;/hb-btn>
    &lt;/template>
&lt;/hb-notification>
</pre>
            </hb-card>
        </hb-card>

        <hb-card title="Success Notification + Code" class="mt-4 mb-6">
            <v-row class="pa-0 ma-0 mx-6 mt-6">
                <hb-notification
                    v-model="notification5"
                    v-if="notification5"
                    type="success"
                >
                    Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                    <template v-slot:condensedActions>
                        <hb-menu
                            options
                            options-hover-type="success"
                        >
                            <v-list>
                                <v-list-item :ripple="false" @click="notification5 = false">
                                    <v-list-item-title>Action</v-list-item-title>
                                </v-list-item>
                            </v-list>
                        </hb-menu>
                    </template>
                    <template v-slot:actions>
                        <hb-btn small color="secondary" @click="notification5 = false">Action</hb-btn>
                    </template>
                </hb-notification>
            </v-row>
            <v-row class="pa-0 ma-0 mx-6 mt-3">
                <hb-notification
                    v-model="notification6"
                    v-if="notification6"
                    type="success"
                >
                    Lorem ipsum dolor sit amet, consectetur adipiscing elit. Donec quis non amet. Lorem ipsum dolor sit amet, consectetur adipiscing elit. Donec quis non amet...
                    <template v-slot:condensedActions>
                        <hb-menu
                            options
                            options-hover-type="success"
                        >
                            <v-list>
                                <v-list-item :ripple="false" @click="notification6 = false">
                                    <v-list-item-title>Action</v-list-item-title>
                                </v-list-item>
                            </v-list>
                        </hb-menu>
                    </template>
                    <template v-slot:actions>
                        <hb-btn small color="secondary" @click="notification6 = false">Action</hb-btn>
                    </template>
                </hb-notification>
            </v-row>

            <v-row class="my-6 d-flex justify-center">
                <hb-btn color="primary" @click="grabCode('success')">Grab Code</hb-btn>
                <div id="success" style="position: absolute;z-index:-999999;top:0;left:0;">
                    &lt;hb-notification
                        v-model="notification5"
                        v-if="notification5"
                        type="success"
                    >
                        Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                        &lt;template v-slot:condensedActions>
                            &lt;hb-menu
                                options
                                options-hover-type="success"
                            >
                                &lt;v-list>
                                    &lt;v-list-item :ripple="false" @click="notification5 = false">
                                        &lt;v-list-item-title>Action&lt;/v-list-item-title>
                                    &lt;/v-list-item>
                                &lt;/v-list>
                            &lt;/hb-menu>
                        &lt;/template>
                        &lt;template v-slot:actions>
                            &lt;hb-btn small color="secondary" @click="notification5 = false">Action&lt;/hb-btn>
                        &lt;/template>
                    &lt;/hb-notification>
                </div>
            </v-row>

            <hb-card class="pa-6" color="#E0F5F5" no-title>
<pre>
&lt;hb-notification
    v-model="notification5"
    v-if="notification5"
    type="success"
>
    Lorem ipsum dolor sit amet, consectetur adipiscing elit.
    &lt;template v-slot:condensedActions>
        &lt;hb-menu
            options
            options-hover-type="success"
        >
            &lt;v-list>
                &lt;v-list-item :ripple="false" @click="notification5 = false">
                    &lt;v-list-item-title>Action&lt;/v-list-item-title>
                &lt;/v-list-item>
            &lt;/v-list>
        &lt;/hb-menu>
    &lt;/template>
    &lt;template v-slot:actions>
        &lt;hb-btn small color="secondary" @click="notification5 = false">Action&lt;/hb-btn>
    &lt;/template>
&lt;/hb-notification>
</pre>
            </hb-card>
        </hb-card>

        <hb-card title="Guidance Notification + Code" class="mt-4 mb-6">
            <v-row class="pa-0 ma-0 mx-6 mt-6">
                <hb-notification
                    v-model="notification7"
                    v-if="notification7"
                    type="guidance"
                >
                    Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                    <template v-slot:condensedActions>
                        <hb-menu
                            options
                            options-hover-type="guidance"
                        >
                            <v-list>
                                <v-list-item :ripple="false" @click="notification7 = false">
                                    <v-list-item-title>Action</v-list-item-title>
                                </v-list-item>
                            </v-list>
                        </hb-menu>
                    </template>
                    <template v-slot:actions>
                        <hb-btn small color="secondary" @click="notification7 = false">Action</hb-btn>
                    </template>
                </hb-notification>
            </v-row>
            <v-row class="pa-0 ma-0 mx-6 mt-3">
                <hb-notification
                    v-model="notification8"
                    v-if="notification8"
                    type="guidance"
                >
                    Lorem ipsum dolor sit amet, consectetur adipiscing elit. Donec quis non amet. Lorem ipsum dolor sit amet, consectetur adipiscing elit. Donec quis non amet...
                    <template v-slot:condensedActions>
                        <hb-menu
                            options
                            options-hover-type="guidance"
                        >
                            <v-list>
                                <v-list-item :ripple="false" @click="notification8 = false">
                                    <v-list-item-title>Action</v-list-item-title>
                                </v-list-item>
                            </v-list>
                        </hb-menu>
                    </template>
                    <template v-slot:actions>
                        <hb-btn small color="secondary" @click="notification8 = false">Action</hb-btn>
                    </template>
                </hb-notification>
            </v-row>

            <v-row class="my-6 d-flex justify-center">
                <hb-btn color="primary" @click="grabCode('success')">Grab Code</hb-btn>
                <div id="guidance" style="position: absolute;z-index:-999999;top:0;left:0;">
                    &lt;hb-notification
                        v-model="notification7"
                        v-if="notification7"
                        type="guidance"
                    >
                        Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                        &lt;template v-slot:condensedActions>
                            &lt;hb-menu
                                options
                                options-hover-type="guidance"
                            >
                                &lt;v-list>
                                    &lt;v-list-item :ripple="false" @click="notification7 = false">
                                        &lt;v-list-item-title>Action&lt;/v-list-item-title>
                                    &lt;/v-list-item>
                                &lt;/v-list>
                            &lt;/hb-menu>
                        &lt;/template>
                        &lt;template v-slot:actions>
                            &lt;hb-btn small color="secondary" @click="notification7 = false">Action&lt;/hb-btn>
                        &lt;/template>
                    &lt;/hb-notification>
                </div>
            </v-row>

            <hb-card class="pa-6" color="#E0F5F5" no-title>
<pre>
&lt;hb-notification
    v-model="notification7"
    v-if="notification7"
    type="guidance"
>
    Lorem ipsum dolor sit amet, consectetur adipiscing elit.
    &lt;template v-slot:condensedActions>
        &lt;hb-menu
            options
            options-hover-type="guidance"
        >
            &lt;v-list>
                &lt;v-list-item :ripple="false" @click="notification7 = false">
                    &lt;v-list-item-title>Action&lt;/v-list-item-title>
                &lt;/v-list-item>
            &lt;/v-list>
        &lt;/hb-menu>
    &lt;/template>
    &lt;template v-slot:actions>
        &lt;hb-btn small color="secondary" @click="notification7 = false">Action&lt;/hb-btn>
    &lt;/template>
&lt;/hb-notification>
</pre>
            </hb-card>
        </hb-card>

        <hb-card title="Quick Actions Notification + Code" class="mt-4 mb-6">
            <v-row class="pa-0 ma-0 mx-6 mt-6">
                <hb-notification
                    v-model="notification9"
                    v-if="notification9"
                    type="quick-actions"
                >
                    <template v-slot:condensedActions>
                        <hb-menu
                            options
                            options-hover-type="quick-actions"
                        >
                            <v-list>
                                <v-list-item :ripple="false">
                                    <v-list-item-title>Move-Out</v-list-item-title>
                                </v-list-item>
                                <v-list-item :ripple="false">
                                    <v-list-item-title>Move-In</v-list-item-title>
                                </v-list-item>
                                <v-list-item :ripple="false">
                                    <v-list-item-title>Take a Payment</v-list-item-title>
                                </v-list-item>
                            </v-list>
                        </hb-menu>
                    </template>
                    <template v-slot:actions>
                        <hb-btn small color="primary">Move-Out</hb-btn>
                        <hb-btn small color="primary" class="ml-2">Move-In</hb-btn>
                        <hb-btn small color="primary" class="ml-2">Take a Payment</hb-btn>
                    </template>
                </hb-notification>
            </v-row>

            <v-row class="my-6 d-flex justify-center">
                <hb-btn color="primary" @click="grabCode('quick-actions')">Grab Code</hb-btn>
                <div id="quick-actions" style="position: absolute;z-index:-999999;top:0;left:0;">
                    &lt;hb-notification
                        v-model="notification9"
                        v-if="notification9"
                        type="quick-actions"
                    >
                        &lt;template v-slot:condensedActions>
                            &lt;hb-menu
                                options
                                options-hover-type="quick-actions"
                            >
                                &lt;v-list>
                                    &lt;v-list-item :ripple="false">
                                        &lt;v-list-item-title>Move-Out&lt;/v-list-item-title>
                                    &lt;/v-list-item>
                                    &lt;v-list-item :ripple="false">
                                        &lt;v-list-item-title>Move-In&lt;/v-list-item-title>
                                    &lt;/v-list-item>
                                    &lt;v-list-item :ripple="false">
                                        &lt;v-list-item-title>Take a Payment&lt;/v-list-item-title>
                                    &lt;/v-list-item>
                                &lt;/v-list>
                            &lt;/hb-menu>
                        &lt;/template>
                        &lt;template v-slot:actions>
                            &lt;hb-btn small color="primary">Move-Out&lt;/hb-btn>
                            &lt;hb-btn small color="primary" class="ml-2">Move-In&lt;/hb-btn>
                            &lt;hb-btn small color="primary" class="ml-2">Take a Payment&lt;/hb-btn>
                        &lt;/template>
                    &lt;/hb-notification>
                </div>
            </v-row>

            <hb-card class="pa-6" color="#E0F5F5" no-title>
<pre>
&lt;hb-notification
    v-model="notification9"
    v-if="notification9"
    type="quick-actions"
>
    &lt;template v-slot:condensedActions>
        &lt;hb-menu
            options
            options-hover-type="quick-actions"
        >
            &lt;v-list>
                &lt;v-list-item :ripple="false">
                    &lt;v-list-item-title>Move-Out&lt;/v-list-item-title>
                &lt;/v-list-item>
                &lt;v-list-item :ripple="false">
                    &lt;v-list-item-title>Move-In&lt;/v-list-item-title>
                &lt;/v-list-item>
                &lt;v-list-item :ripple="false">
                    &lt;v-list-item-title>Take a Payment&lt;/v-list-item-title>
                &lt;/v-list-item>
            &lt;/v-list>
        &lt;/hb-menu>
    &lt;/template>
    &lt;template v-slot:actions>
        &lt;hb-btn small color="primary">Move-Out&lt;/hb-btn>
        &lt;hb-btn small color="primary" class="ml-2">Move-In&lt;/hb-btn>
        &lt;hb-btn small color="primary" class="ml-2">Take a Payment&lt;/hb-btn>
    &lt;/template>
&lt;/hb-notification>
</pre>
            </hb-card>
        </hb-card>

        <hb-card title="Props" class="my-6 pb-1">
            <hb-data-table
                :headers="propHeaders"
                :items="propItems"
                class="pa-6"
            >
            </hb-data-table>
        </hb-card>

        <hb-card title="Slots" class="my-6 pb-1">
            <hb-data-table
                :headers="slotHeaders"
                :items="slotItems"
                class="pa-6"
                >
            </hb-data-table>
        </hb-card>

        <hb-card title="Events" class="my-6 pb-1">
            <hb-data-table
                :headers="eventHeaders"
                :items="eventItems"
                class="pa-6"
            >
            </hb-data-table>
        </hb-card>

        <hb-global-notification
            v-model="success"
            type="success"
            :description="snackbarItem + ' <hb-notification> component code copied to clipboard.'"
        >
        </hb-global-notification>

    </div>
</template>
<script type="text/babel">

    export default {
        name: "DesignSystemNotifications",
        data: function(){
            return {
                notification1: true,
                notification2: true,
                notification3: true,
                notification4: true,
                notification5: true,
                notification6: true,
                notification7: true,
                notification8: true,
                notification9: true,
                success: false,
                snackbarItem: '',
                propHeaders: [
                    { text: 'Name', value: 'name', width: '150' },
                    { text: 'Type', value: 'type', width: '110' },
                    { text: 'Default', value: 'default', width: '110' },
                    { text: 'Description', value: 'description' },
                ],
                propItems: [
                    { name: 'v-model', type: 'boolean', default: 'false', description: 'Set a data property as the v-model with default value of false. When the v-model property value is changed to true, the notification will show. Turn it back to false to close the notification or hit the top right corner "x" close button.' },
                    { name: 'type', type: 'string', default: 'undefined', description: 'Sets the type of notification and corresponding color styles. The options are "caution", "error", "success", "guidance", and "quick-actions".' },
                    { name: 'title', type: 'string', default: 'undefined', description: 'Overrides the main title of the notification. For example, you can set the prop "type" to "success" and instead of displaying the default "Success:" text, you can override that text through this prop.' },
                    { name: 'title-off', type: 'boolean', default: 'false', description: 'If changed to true, this turns off the main notification title text.' },
                    { name: 'not-dismissable', type: 'boolean', default: 'false', description: 'If changed to true, this makes the notification stay permanently on the page. This also turns off the close icon.' },
                    { name: 'left-cols', type: 'number', default: 'undefined', description: 'Sets the left column value from 1 to 12. Please use a maximum value of 11 for this if showing the "close" / "dismiss" icon button. If the sum of left-columns and right-columns does not add up to 12, then the visual integratity of this component cannot be guaranteed.' },
                    { name: 'right-cols', type: 'number', default: 'undefined', description: 'Sets the right column value from 1 to 12. Please leave a minimum value of 1 for this if showing the "close" / "dismiss" icon button. If the sum of left-columns and right-columns does not add up to 12, then the visual integratity of this component cannot be guaranteed.' },
                    { name: 'mdi-code', type: 'string', default: 'undefined', description: 'Set the main icon manually by passing in an "mdi-xxx" code.' },
                    { name: 'icon-off', type: 'boolean', default: 'false', description: 'If changed to true, this will turn off the main icon.' },
                    { name: 'icon-color', type: 'string', default: 'undefined', description: 'Override the default main icon color. You should not really ever need to use this prop.' },
                ],
                slotHeaders: [
                    { text: 'Name', value: 'name' },
                    { text: 'Description', value: 'description' },
                ],
                slotItems: [
                    { name: 'actions', description: 'This slot is normally where you place the action links or buttons related to the notification.' },
                    { name: 'condensedActions', description: 'This slot is normally where you place the alternative action functionality when content has gone to two lines. Usually you would use an "<HbMenu options...>" component. See examples for proper usage.' },
                ],
                eventHeaders: [
                    { text: 'Name', value: 'name' },
                    { text: 'Description', value: 'description' },
                ],
                eventItems: [
                    { name: '@close', description: 'Event for when close icon is clicked.' },
                    { name: '@condense-actions', description: 'Event for when the component toggles condensed actions mode. Payload will be true or false.' }
                ],
            }
        },
        methods: {
            grabCode(code){
                this.snackbarItem = code;
                window.getSelection().empty();
                var range = document.createRange();
                range.selectNode(document.getElementById(code));
                window.getSelection().addRange(range);
                document.execCommand("copy");
                this.success = true;
            },
            resetPage(){
                this.notification1 = true;
                this.notification2 = true;
                this.notification3 = true;
                this.notification4 = true;
                this.notification5 = true;
                this.notification6 = true;
                this.notification7 = true;
                this.notification8 = true;
                this.notification9 = true;
            }
        }
    }
</script>

<style scoped>

</style>